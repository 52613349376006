var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "EventModal",
    attrs: {
      "show": _vm.isModalOpen,
      "title": _vm.modalTitle,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleSaveEvent
      },
      "cancel": {
        text: 'Abbrechen',
        callback: _vm.handleCancelEvent
      },
      "isLoading": _vm.isSavingEvent
    },
    on: {
      "onModalClose": _vm.handleCancelEvent
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.modalDescription))]), _c('br'), _c('div', {
    staticClass: "InputWrap"
  }, [_c('Dropdown', {
    attrs: {
      "variant": "single-column",
      "hasRemoveIcon": false,
      "items": _vm.drivers.filter(function (d) {
        return d.isVisible;
      }),
      "selected": _vm.internalEvent.title,
      "hasSearch": "",
      "placeholder": "Fahrer"
    },
    on: {
      "onItemSelect": _vm.handleDriverSelect
    }
  })], 1), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "notice"
    }
  }, [_vm._v("Notiz")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.internalEvent.notice,
      expression: "internalEvent.notice"
    }],
    staticClass: "Input",
    attrs: {
      "id": "notice",
      "required": ""
    },
    domProps: {
      "value": _vm.internalEvent.notice
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.internalEvent, "notice", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Fügen Sie hier eine Beschreibung hinzu.")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }