<template>
    <div class="ToggleSwitchWrap">
        <slot name="left" />
        <div class="ToggleSwitch" :class="{ 'ToggleSwitch--hasError': hasError }">
            <input
                type="checkbox"
                :id="name || value"
                :name="name"
                :disabled="isDisabled"
                :checked="value"
                @change="handleChange"
                class="ToggleSwitch-input"
            />
            <label :for="name || value" class="ToggleSwitch-label"></label>
        </div>
        <slot name="right" />
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        hasError: Boolean,
        isDisabled: Boolean,
        onToggle: {
            type: Function,
            default: () => ({}),
        },
        value: Boolean,
    },
    methods: {
        handleChange(e) {
            const newValue = e.target.checked;
            this.$emit('onToggle', newValue);
            this.onToggle(newValue);
        },
    },
};
</script>
<style lang="scss" scoped>
.ToggleSwitchWrap {
    display: flex;
    font-size: 12px;
    gap: 4px;
    align-items: center;

    .ToggleSwitch {
        display: flex;
        align-items: center;
        margin: 10px 0;

        &.ToggleSwitch--hasError {
            color: red; // Adjust as needed
        }

        .ToggleSwitch-input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .ToggleSwitch-label {
            position: relative;
            display: inline-block;
            background-color: #ccc;
            width: 50px;
            height: 24px;
            border-radius: 24px;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                left: 3px;
                top: 2px;
                background-color: white;
                border-radius: 50%;
                transition: 0.4s;
            }
        }

        .ToggleSwitch-input:checked + .ToggleSwitch-label {
            background-color: var(--color-blue-dark);

            &:after {
                transform: translateX(24px);
            }
        }

        label,
        a {
            font-size: 18px; // Adjust as needed
            line-height: 1.5;
            user-select: none;
            color: inherit;
            flex: 1;
            cursor: pointer;
        }
    }
}
</style>
