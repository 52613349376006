var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navigation-buttons"
  }, [_c('Button', {
    attrs: {
      "variant": "basic",
      "size": "small"
    },
    on: {
      "onClick": _vm.goToPrevious
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "24",
      "fill": "currentColor",
      "viewBox": "0 0 16 16"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M10.707 3.293a1 1 0 010 1.414L6.414 8l4.293 4.293a1 1 0 11-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z",
      "clip-rule": "evenodd"
    }
  })])]), _c('Button', {
    attrs: {
      "variant": "basic",
      "size": "small"
    },
    on: {
      "onClick": _vm.goToToday
    }
  }, [_vm._v("Heute")]), _c('Button', {
    attrs: {
      "variant": "basic",
      "size": "small"
    },
    on: {
      "onClick": _vm.goToNext
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "12",
      "fill": "currentColor",
      "viewBox": "0 0 16 16"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M5.293 3.293a1 1 0 010 1.414L9.586 8l-4.293 4.293a1 1 0 101.414 1.414l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 0z",
      "clip-rule": "evenodd"
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }