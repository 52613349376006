var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "resource-list-container"
  }, [_c('h3', [_vm._v("Taxis")]), _c('ul', {
    staticClass: "resource-list"
  }, _vm._l(_vm.resources, function (resource) {
    return _c('li', {
      key: resource.id,
      staticClass: "resource-item"
    }, [_vm._v(" " + _vm._s(resource.value) + " ")]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }