<template>
    <div class="resource-list-container">
        <h3>Taxis</h3>
        <ul class="resource-list">
            <li v-for="resource in resources" :key="resource.id" class="resource-item">
                {{ resource.value }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ResourceList',
    props: {
        resources: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
.resource-list-container {
    h3 {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
        padding: 19px;
        margin-bottom: 8px;
    }

    .resource-list {
        list-style-type: none;
        padding: 0;
        margin-top: -8px;
        margin-right: 0px;
        min-width: 150px;
        font-size: 14px;
    }

    .resource-item {
        padding: 0px 4px;
        border: 1px solid var(--color-border);

        &:not(:last-child) {
            border-bottom: none;
        }
    }
}
</style>
