<template>
    <div class="AssigneeContainer">
        <ul class="AssigneeList">
            <draggable
                :list="list"
                :disabled="!enabled"
                class="list-group AssigneeList"
                ghost-class="ghost"
                :group="{ name: 'events', pull: 'clone', put: false }"
                :move="checkMove"
                :clone="original => original"
                @start="dragging = true"
                @end="dragging = false"
            >
                <div
                    class="list-group-item AssigneeItem event"
                    v-for="element in list"
                    :key="element.uuid"
                    :data-id="element.uuid"
                    :data-name="element.name"
                    :class="[
                        {
                            isHighlighted:
                                selectedAssignee && selectedAssignee.uuid === element.uuid,
                        },
                        { 'is-day-driver': element.isDayDriver },
                    ]"
                    @click="onAssigneeClick(element)"
                >
                    <span>
                        {{ element.name }}
                    </span>
                </div>
            </draggable>
        </ul>
    </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
    name: 'AssigneeList',
    components: {
        draggable,
    },
    props: {
        assignees: {
            type: Array,
            required: true,
        },
        selectedAssignee: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            drag: false,
        };
    },
    computed: {
        list() {
            return this.assignees;
        },
        enabled() {
            return !this.drag;
        },
    },
    methods: {
        onAssigneeClick(assignee) {
            this.$emit('assignee-click', assignee);
        },
        checkMove: function(e) {
            window.console.log('Future index: ' + e.draggedContext.futureIndex);
        },
        handleDragStart() {
            this.drag = true;
            console.log('handleDragStart', this.drag);
        },
        handleDragEnd() {
            this.drag = false;
            console.log('handleDragEnd', this.drag);
        },
    },
    mounted() {
        console.log('AssigneeList mounted', this.assignees);
    },
};
</script>

<style lang="scss">
.AssigneeContainer {
    .AssigneeList {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-right: 2px;
        gap: 4px;
        font-size: 14px;
    }

    .AssigneeItem {
        padding: 0px 4px;
        background-color: var(--color-blue-light);
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
        border: 1px solid var(--color-border);
        color: #ffffff;
        font-size: 0.75rem;
        position: relative;
        width: auto;

        &.is-day-driver {
            background-color: var(--color-yellow-dark);
            color: #000000;
        }

        &:hover {
            background-color: var(--color-blue-dark);
            color: #ffffff;
        }

        &.isHighlighted {
            background-color: color(green-dark);

            &:hover {
                background-color: lighten(color(green-dark), 10%);
                color: #ffffff;
            }
        }
    }
}
.AssigneeItem--ghost {
    opacity: 0.5;
    font-size: 0.75rem;
}
</style>
