var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "time-grid"
  }, [_vm.variant === '24h' ? _c('div', [_c('div', {
    staticClass: "time-grid-header"
  }, [_c('div', {
    staticClass: "time-grid-cell"
  }, [_vm._v(_vm._s(_vm.formatDay))])]), _c('div', {
    staticClass: "time-grid-body"
  }, [_c('div', {
    staticClass: "hours-grid"
  }, _vm._l(_vm.hours, function (hour) {
    return _c('div', {
      key: hour,
      staticClass: "hour-label"
    }, [_vm._v(_vm._s(hour))]);
  }), 0), _vm._l(_vm.resources, function (resource) {
    return _c('div', {
      key: resource.id,
      staticClass: "time-grid-row"
    }, [_c('div', {
      staticClass: "hours-grid"
    }, _vm._l(_vm.hours, function (hour) {
      return _c('draggable', {
        key: hour,
        staticClass: "hour-cell",
        class: {
          'range-selecting': _vm.isCellInRange({
            hour: hour,
            resourceId: resource.id
          })
        },
        attrs: {
          "swapThreshold": 0.1,
          "delay": 200,
          "data-hour": hour,
          "group": "events",
          "handle": ".event span",
          "ghostClass": "draggable-ghost",
          "data-resource-id": resource.id
        },
        on: {
          "start": function start($event) {
            _vm.drag = true;
          },
          "end": function end($event) {
            _vm.drag = false;
          },
          "add": function add(e) {
            return _vm.onUpdateEventList(e, {
              hour: hour,
              resourceId: resource.id
            });
          }
        },
        nativeOn: {
          "mousedown": function mousedown($event) {
            return _vm.onCellMouseDown({
              hour: hour,
              resourceId: resource.id
            });
          },
          "mouseup": function mouseup($event) {
            return _vm.onCellMouseUp({
              hour: hour,
              resourceId: resource.id
            });
          },
          "mouseover": function mouseover($event) {
            return _vm.onCellMouseOver({
              hour: hour,
              resourceId: resource.id
            });
          }
        }
      }, _vm._l(_vm.filteredEvents({
        resourceId: resource.id,
        hour: hour
      }), function (event) {
        return _c('div', {
          key: event.id,
          staticClass: "event",
          style: _vm.getEventStyle(event),
          attrs: {
            "data-event-id": event.id
          },
          on: {
            "mouseup": function mouseup($event) {
              $event.stopPropagation();
              return _vm.handleEditEvent(event);
            },
            "mousedown": function mousedown($event) {
              $event.stopPropagation();
            },
            "mouseover": function mouseover($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('span', [_vm._v(_vm._s(event.title))]), _c('span', {
          staticClass: "delete-icon",
          on: {
            "mouseup": function mouseup($event) {
              $event.stopPropagation();
              return _vm.handleDeleteEvent(event);
            }
          }
        }, [_vm._v("✕")]), _c('div', {
          staticClass: "resize-handle",
          on: {
            "mousedown": function mousedown($event) {
              $event.stopPropagation();
              return function (e) {
                return _vm.onResizeStart(e, event.id);
              }.apply(null, arguments);
            }
          }
        })]);
      }), 0);
    }), 1)]);
  })], 2)]) : _vm._e(), _vm.variant === 'day-night' ? _c('div', {
    staticClass: "day-night-grid-container"
  }, [_c('div', {
    staticClass: "time-grid-header"
  }, _vm._l(_vm.weekDays, function (_ref) {
    var dayString = _ref.dayString;
    return _c('div', {
      key: dayString,
      staticClass: "time-grid-cell-day"
    }, [_c('div', {
      staticClass: "time-grid-cell"
    }, [_vm._v(_vm._s(dayString))]), _c('div', {
      staticClass: "time-grid-periods"
    }, _vm._l(['Tag', 'Nacht'], function (period) {
      return _c('div', {
        key: period,
        staticClass: "time-grid-period"
      }, [_vm._v(" " + _vm._s(period) + " ")]);
    }), 0)]);
  }), 0), _c('div', {
    staticClass: "time-grid-body"
  }, _vm._l(_vm.resources, function (resource) {
    return _c('div', {
      key: resource.id,
      staticClass: "time-grid-row",
      attrs: {
        "data-resource-id": resource.id
      }
    }, _vm._l(_vm.weekDays, function (_ref2) {
      var day = _ref2.day,
        dayString = _ref2.dayString;
      return _c('div', {
        key: dayString,
        staticClass: "day-night-grid"
      }, _vm._l(['Tag', 'Nacht'], function (period) {
        return _c('draggable', {
          key: period,
          staticClass: "day-night-cell",
          attrs: {
            "swapThreshold": 0.1,
            "delayOnTouchOnly": true,
            "tabindex": "0",
            "group": "events",
            "ghostClass": "draggable-ghost"
          },
          on: {
            "start": function start($event) {
              _vm.drag = true;
            },
            "end": function end($event) {
              _vm.drag = false;
            },
            "add": function add(e) {
              return _vm.onUpdateEventList(e, {
                period: period,
                resourceId: resource.id,
                day: day
              });
            }
          },
          nativeOn: {
            "mouseup": function mouseup($event) {
              return _vm.onCellMouseUp({
                period: period,
                resourceId: resource.id,
                day: day
              });
            }
          }
        }, _vm._l(_vm.filteredEvents({
          resourceId: resource.id,
          period: period,
          day: day
        }), function (event) {
          return _c('div', {
            directives: [{
              name: "tooltip",
              rawName: "v-tooltip",
              value: event.title,
              expression: "event.title"
            }],
            key: event.id,
            staticClass: "event",
            style: _vm.getEventStyle(event),
            attrs: {
              "data-event-id": event.id
            },
            on: {
              "mouseup": function mouseup($event) {
                $event.stopPropagation();
                return _vm.handleEditEvent(event);
              }
            }
          }, [_c('span', [_vm._v(_vm._s(event.title))]), !_vm.drag ? _c('span', {
            directives: [{
              name: "tooltip",
              rawName: "v-tooltip",
              value: 'Löschen',
              expression: "'Löschen'"
            }],
            staticClass: "delete-icon",
            on: {
              "mouseup": function mouseup($event) {
                $event.stopPropagation();
                return _vm.handleDeleteEvent(event);
              }
            }
          }, [_vm._v("✕")]) : _vm._e()]);
        }), 0);
      }), 1);
    }), 0);
  }), 0)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }