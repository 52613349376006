<template>
    <div class="time-grid">
        <!-- 24h View -->
        <div v-if="variant === '24h'">
            <div class="time-grid-header">
                <div class="time-grid-cell">{{ formatDay }}</div>
            </div>
            <div class="time-grid-body">
                <div class="hours-grid">
                    <div class="hour-label" v-for="hour in hours" :key="hour">{{ hour }}</div>
                </div>
                <div class="time-grid-row" v-for="resource in resources" :key="resource.id">
                    <div class="hours-grid">
                        <draggable
                            v-for="hour in hours"
                            class="hour-cell"
                            :swapThreshold="0.1"
                            :delay="200"
                            :key="hour"
                            :data-hour="hour"
                            group="events"
                            @start="drag = true"
                            @end="drag = false"
                            handle=".event span"
                            @add="e => onUpdateEventList(e, { hour, resourceId: resource.id })"
                            ghostClass="draggable-ghost"
                            :data-resource-id="resource.id"
                            :class="{
                                'range-selecting': isCellInRange({ hour, resourceId: resource.id }),
                            }"
                            @mousedown.native="onCellMouseDown({ hour, resourceId: resource.id })"
                            @mouseup.native="onCellMouseUp({ hour, resourceId: resource.id })"
                            @mouseover.native="onCellMouseOver({ hour, resourceId: resource.id })"
                        >
                            <div
                                v-for="event in filteredEvents({ resourceId: resource.id, hour })"
                                :key="event.id"
                                class="event"
                                :data-event-id="event.id"
                                :style="getEventStyle(event)"
                                @mouseup.stop="handleEditEvent(event)"
                                @mousedown.stop=""
                                @mouseover.stop=""
                            >
                                <span>{{ event.title }}</span>
                                <span class="delete-icon" @mouseup.stop="handleDeleteEvent(event)"
                                    >&#x2715;</span
                                >
                                <div
                                    class="resize-handle"
                                    @mousedown.stop="e => onResizeStart(e, event.id)"
                                ></div>
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>
        </div>

        <!-- Day-Night View -->
        <div v-if="variant === 'day-night'" class="day-night-grid-container">
            <div class="time-grid-header">
                <div v-for="{ dayString } in weekDays" :key="dayString" class="time-grid-cell-day">
                    <div class="time-grid-cell">{{ dayString }}</div>
                    <div class="time-grid-periods">
                        <div
                            class="time-grid-period"
                            v-for="period in ['Tag', 'Nacht']"
                            :key="period"
                        >
                            {{ period }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="time-grid-body">
                <div
                    class="time-grid-row"
                    v-for="resource in resources"
                    :key="resource.id"
                    :data-resource-id="resource.id"
                >
                    <div
                        class="day-night-grid"
                        v-for="{ day, dayString } in weekDays"
                        :key="dayString"
                    >
                        <draggable
                            v-for="period in ['Tag', 'Nacht']"
                            :swapThreshold="0.1"
                            :delayOnTouchOnly="true"
                            :key="period"
                            class="day-night-cell"
                            tabindex="0"
                            group="events"
                            @mouseup.native="
                                onCellMouseUp({ period, resourceId: resource.id, day })
                            "
                            @start="drag = true"
                            @end="drag = false"
                            @add="
                                e => onUpdateEventList(e, { period, resourceId: resource.id, day })
                            "
                            ghostClass="draggable-ghost"
                        >
                            <div
                                v-for="event in filteredEvents({
                                    resourceId: resource.id,
                                    period,
                                    day,
                                })"
                                class="event"
                                :data-event-id="event.id"
                                :key="event.id"
                                :style="getEventStyle(event)"
                                v-tooltip="event.title"
                                @mouseup.stop="handleEditEvent(event)"
                            >
                                <span>{{ event.title }}</span>
                                <span
                                    v-if="!drag"
                                    class="delete-icon"
                                    v-tooltip="'Löschen'"
                                    @mouseup.stop="handleDeleteEvent(event)"
                                    >&#x2715;</span
                                >
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { format, eachDayOfInterval, startOfWeek, endOfWeek, isSameDay, setHours } from 'date-fns';
import de from 'date-fns/locale/de';
import draggable from 'vuedraggable';
export default {
    name: 'TimeGrid',
    components: {
        draggable,
    },
    props: {
        resources: Array,
        startDate: Date,
        events: Array,
        variant: {
            type: String,
            default: 'day-night', // or '24h'
        },
    },
    data() {
        return {
            drag: false,
            currentDrag: null,
            currentResize: null,
            rangeStart: null,
            rangeEnd: null,
            isRangeSelecting: false,
            internalEvents: [],
            dayNightHours: { Tag: '06:00', Nacht: '18:00' },
        };
    },
    watch: {
        startDate() {
            this.isRangeSelecting = false;
            this.rangeStart = null;
            this.rangeEnd = null;
        },
        events: {
            immediate: true,
            handler(events) {
                this.internalEvents = events;
            },
        },
    },
    computed: {
        hours() {
            return this.variant === 'day-night'
                ? ['Tag', 'Nacht']
                : Array.from({ length: 24 }, (_, i) => `${i}:00`);
        },

        gridColumns() {
            return this.variant === 'day-night' ? '1fr 1fr' : 'repeat(24, 70px)';
        },
        formatDay() {
            return format(this.startDate, 'EEE dd/MM', { weekStartsOn: 1, locale: de });
        },
        weekDays() {
            const start = startOfWeek(this.startDate, { weekStartsOn: 1 });
            const end = endOfWeek(this.startDate, { weekStartsOn: 1 });
            return eachDayOfInterval({ start, end }).map(day => ({
                day,
                dayString: format(day, 'EEE dd/MM', { weekStartsOn: 1, locale: de }),
            }));
        },
    },
    methods: {
        onDriverDrop({ event, day, period, resourceId }) {
            const driverId = event.item.dataset.id;

            // Prepare new event with driver assigned
            const newEvent = {
                title: event.item.innerText,
                startDate: new Date(day),
                endDate: new Date(day),
                resourceId,
                driverId,
            };
            if (period === 'Tag') {
                newEvent.startDate.setHours(6);
                newEvent.endDate.setHours(18);
            } else {
                newEvent.startDate.setHours(18);
                newEvent.endDate.setHours(6);
            }

            this.$emit('onCreateEvent', newEvent);
        },
        onUpdateEventList(e, { hour, period, resourceId, day }) {
            console.log('onUpdateEventList', resourceId);
            const event = this.internalEvents.find(event => event.id === e.item.dataset.eventId);
            const draggedDriverId = e.item.dataset.id;

            if (draggedDriverId) {
                // if the dragged item is a driver
                this.onDriverDrop({ event: e, day, period, resourceId });
                return;
            }
            if (this.variant === 'day-night') {
                const start = new Date(day);
                const end = new Date(day);
                if (period === 'Tag') {
                    start.setHours(6);
                    end.setHours(18);
                } else {
                    start.setHours(18);
                    end.setHours(6);
                }
                event.startDate = start;
                event.endDate = end;
                event.resourceId = resourceId;

                this.$emit('onUpdateEvent', event);
            }
            if (this.variant === '24h') {
                const start = new Date(event.startDate);
                const end = new Date(event.endDate);
                const hourDuration = Number(format(end, 'HH')) - Number(format(start, 'HH'));
                const eventHour = new Date(event.startDate).getHours();
                // hour is in the format '17:00'
                const [hourString] = hour.split(':');

                event.startDate = setHours(start, hourString);
                event.endDate = setHours(end, eventHour + hourDuration);

                event.resourceId = resourceId;
                this.$emit('onUpdateEvent', event);
            }
        },
        getEventStyle(event) {
            if (this.variant === 'day-night') {
                const start = new Date(event.startDate);
                const end = new Date(event.endDate);
                const hourDuration = Number(format(end, 'HH')) - Number(format(start, 'HH'));
                const hourCellHeight = 28;
                const durationInMinutes = (end - start) / (1000 * 60);
                const topOffset =
                    (start.getHours() * 60 + start.getMinutes()) * (hourCellHeight / 60);
                const height = durationInMinutes * (hourCellHeight / 60);

                return {
                    height: `${hourCellHeight - 6}px`,
                    width: `${100 - 5}px`,
                    left: '2px',
                    top: `2px`,
                    position: 'absolute',
                };
            }
            const start = new Date(event.startDate);
            const end = new Date(event.endDate);
            const hourDuration = Number(format(end, 'HH')) - Number(format(start, 'HH'));
            const hourCellHeight = 28;
            const durationInMinutes = (end - start) / (1000 * 60);
            const topOffset = (start.getHours() * 60 + start.getMinutes()) * (hourCellHeight / 60);
            const height = durationInMinutes * (hourCellHeight / 60);

            return {
                height: `${hourCellHeight}px`,
                width: `${(hourDuration + 1) * 70}px`,
                left: '0',
                right: '0',
                position: 'absolute',
            };
        },

        filteredEvents({ resourceId, period, hour, day = null }) {
            if (this.variant === 'day-night') {
                return this.internalEvents.filter(event => {
                    const startHour = new Date(event.startDate).getHours();
                    const isDay = period === 'Tag' && startHour >= 6 && startHour < 18;
                    const isNight = period === 'Nacht' && (startHour >= 18 || startHour < 6);
                    const sameDay = isSameDay(new Date(event.startDate), day);

                    const condition =
                        (isDay || isNight) && event.resourceId === resourceId && sameDay;

                    return condition;
                });
            } else {
                return this.internalEvents.filter(event => {
                    const eventStartHour = new Date(event.startDate).getHours();
                    const hourNumber = parseInt(hour.split(':')[0]);
                    const sameDay = isSameDay(new Date(event.startDate), this.startDate);
                    return (
                        eventStartHour === hourNumber && event.resourceId === resourceId && sameDay
                    );
                });
            }
        },

        isEventInHour(event, hour) {
            const eventStartHour = new Date(event.startDate).getHours();
            const hourNumber = parseInt(hour.split(':')[0]);
            return eventStartHour === hourNumber;
        },
        onCellMouseDown({ hour, resourceId }) {
            if (this.drag) return;
            const [hourString] = hour.split(':');
            this.isRangeSelecting = true;
            this.rangeStart = { hour: hourString, resourceId };
        },
        onCellMouseOver({ hour, resourceId }) {
            if (this.drag) return;

            if (this.isRangeSelecting) {
                // Update range end based on the cell being hovered over
                const [hourString] = hour.split(':');
                this.rangeEnd = { hour: hourString, resourceId };
            }
        },

        isCellInRange({ hour, resourceId }) {
            if (this.drag) return;

            if (!this.isRangeSelecting || !this.rangeStart || !this.rangeEnd) return false;

            // Convert hour string to a number
            const hourNumber = parseInt(hour.split(':')[0]);

            // Convert range start and end to numbers
            const rangeStartHour = parseInt(this.rangeStart.hour);
            const rangeEndHour = parseInt(this.rangeEnd.hour);

            // Check if the current cell's hour and resource ID are within the range
            const isHourInRange = hourNumber >= rangeStartHour && hourNumber <= rangeEndHour;
            const isSameResource = this.rangeStart.resourceId === resourceId;

            return isHourInRange && isSameResource;
        },
        handleDeleteEvent(event) {
            this.$emit('onDeleteEvent', event);
        },
        handleEditEvent(event) {
            if (this.drag) return;
            this.$emit('onEditEvent', event);
        },
        onCellMouseUp({ hour, resourceId, period, day }) {
            if (this.drag) return;

            try {
                if (this.variant === 'day-night') {
                    // set a fixed start and end time based on the period
                    const startDate = new Date(day);
                    const endDate = new Date(day);
                    if (period === 'Tag') {
                        startDate.setHours(6);
                        endDate.setHours(18);
                    } else {
                        startDate.setHours(18);
                        endDate.setHours(6);
                    }

                    this.createNewEvent({ startDate, endDate, resourceId });
                    this.rangeStart = null;
                    this.rangeEnd = null;
                } else {
                    const [hourString] = hour.split(':');

                    if (this.isRangeSelecting) {
                        this.rangeEnd = { hour: hourString, resourceId };
                        this.isRangeSelecting = false;

                        // Create a new event based on the range
                        this.createNewEvent({
                            rangeStart: this.rangeStart,
                            rangeEnd: this.rangeEnd,
                        });
                        this.rangeStart = null;
                        this.rangeEnd = null;
                    }
                }
            } catch (error) {}
        },
        createNewEvent({ startDate, endDate, resourceId, rangeStart, rangeEnd }) {
            try {
                const newEvent = {
                    title: '',
                    startDate: startDate || this.calculateNewStartTime(rangeStart),
                    endDate: endDate || this.calculateNewEndTime(rangeEnd),
                    resourceId: resourceId || rangeStart.resourceId,
                };
                this.$emit('onCreateEvent', newEvent);
            } catch (error) {}
        },
        calculateNewStartTime(rangeStart) {
            const { hour } = rangeStart;

            const start = new Date(this.startDate);
            start.setHours(hour);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            return start;
        },
        calculateNewEndTime(rangeEnd) {
            const { hour } = rangeEnd;
            const end = new Date(this.startDate);
            end.setHours(hour);
            end.setMinutes(0);
            end.setSeconds(0);
            end.setMilliseconds(0);
            return end;
        },
        onResizeStart(event, eventId) {
            event.preventDefault();
            event.stopPropagation();
            this.drag = true;
            const eventObj = this.internalEvents.find(e => e.id === eventId);
            if (eventObj) {
                this.currentResize = {
                    eventId,
                    originalEndDate: new Date(eventObj.endDate),
                    startX: event.clientX,
                };
            }
            // Add mousemove and mouseup listeners
            window.addEventListener('mousemove', this.onResizeMove);
            window.addEventListener('mouseup', this.onResizeEnd);
        },

        onResizeMove(event) {
            if (!this.currentResize) return;

            // Calculate the change in X position
            const deltaX = event.clientX - this.currentResize.startX;
            const additionalHours = Math.round(deltaX / 70);
            const newEndDate = new Date(this.currentResize.originalEndDate);
            newEndDate.setHours(newEndDate.getHours() + additionalHours);

            // Update the event in internalEvents
            const eventIndex = this.internalEvents.findIndex(
                e => e.id === this.currentResize.eventId,
            );
            if (eventIndex !== -1) {
                this.internalEvents[eventIndex].endDate = newEndDate;
            }
        },

        onResizeEnd() {
            // Remove event listeners
            window.removeEventListener('mousemove', this.onResizeMove);
            window.removeEventListener('mouseup', this.onResizeEnd);

            // Emit an update event
            const updatedEvent = this.internalEvents.find(e => e.id === this.currentResize.eventId);
            if (updatedEvent) {
                this.$emit('onUpdateEvent', updatedEvent);
                // Clear current resize info
                this.currentResize = null;
            }
            this.drag = false;
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
.time-grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: auto;
    border: 1px solid var(--color-border);
    border-bottom: none;
    max-width: calc(100vw - 450px);

    @media (max-width: 768px) {
        max-width: calc(100vw - 220px);
    }
}

.time-grid-header,
.time-grid-row {
    display: flex;
}

.time-grid-header {
}

.time-grid-cell,
.time-grid-row {
    flex: 1;
}

.time-grid-body {
    flex: 1;
    overflow: auto;
}

.time-grid-cell {
    flex: 1;
    border: 1px solid var(--color-border);
    border-top: none;
    border-right: none;
    border-left: none;
    padding: 4px;
    text-align: center;
}

.event {
    position: absolute;
    background-color: var(--color-blue-light);
    cursor: pointer;
    width: 100%;
    color: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    padding-left: 4px;

    &:hover {
        background-color: var(--color-blue-dark);

        .delete-icon {
            opacity: 1;
            visibility: visible;
        }
    }

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 90%;
    }

    .delete-icon {
        z-index: 2;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        right: 3px;
        top: 3px;
        background-color: #fff;
        border-radius: 2px;
        max-height: 16px;
        display: flex;
        line-height: 1;
        cursor: pointer;
        padding: 2px;
        color: var(--color-red);
    }
}

.resize-handle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: se-resize;
}

.hours-grid {
    --grid-columns: repeat(24, 70px);
    display: grid;
    grid-template-columns: var(--grid-columns);
}

.hour-label {
    border: 1px solid var(--color-border);
    border-top: none;
    border-right: none;
    text-align: center;
    height: 28px;
    width: 70px;

    &:first-child {
        border-left: none;
    }
}

.hour-cell {
    border: 1px solid var(--color-border);
    border-top: none;
    cursor: pointer;
    border-right: none;
    position: relative;
    text-align: center;
    height: 28px;
    width: 70px;

    &:first-child {
        border-left: none;
    }

    &:hover {
        background-color: #dad8f1;
    }

    &.range-selecting {
        background-color: var(--color-blue-dark);
    }
}

.day-night-grid-container {
    min-width: 1414px;
}

.time-grid-cell-day {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-border);
}

.time-grid-periods {
    display: flex;

    * {
        text-align: center;
        width: 100px;
    }
}

.time-grid-period {
    text-align: center;
    width: 100px;
}

.day-night-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.day-night-cell {
    border: 1px solid var(--color-border);
    width: 101px;
    height: 28px;
    position: relative;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        background-color: #dad8f1;
    }
}

.draggable-ghost {
    opacity: 0.7;
    background-color: var(--color-blue-light);
    border: 1px dashed var(--color-blue-dark);
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
    transition: transform 0.2s ease;
}
</style>
