export function saveEventsToLocalStorage(events) {
    localStorage.setItem('schedulerEvents', JSON.stringify(events));
}

export function loadEventsFromLocalStorage() {
    const events = localStorage.getItem('schedulerEvents');
    return events ? JSON.parse(events) : [];
}

export function deleteEventFromLocalStorage(eventId) {
    const events = loadEventsFromLocalStorage();
    const updatedEvents = events.filter(event => event.id !== eventId);
    saveEventsToLocalStorage(updatedEvents);
    return updatedEvents;
}

// utils/localStorage.js
export function saveDriverScheduleToLocalStorage(driverSchedule) {
    localStorage.setItem('driverSchedule', JSON.stringify(driverSchedule));
}

export function loadDriverScheduleFromLocalStorage() {
    const savedSchedule = localStorage.getItem('driverSchedule');
    return savedSchedule ? JSON.parse(savedSchedule) : [];
}

export function deleteDriverScheduleEventFromLocalStorage(eventId) {
    const driverSchedule = loadDriverScheduleFromLocalStorage();
    const updatedSchedule = driverSchedule.filter(event => event.id !== eventId);
    saveDriverScheduleToLocalStorage(updatedSchedule);
    return updatedSchedule;
}

// Function to save the default schedule
export function saveDefaultScheduleToLocalStorage(driverSchedule) {
    localStorage.setItem('defaultDriverSchedule', JSON.stringify(driverSchedule));
}

// Function to load the default schedule
export function loadDefaultScheduleFromLocalStorage() {
    const defaultSchedule = localStorage.getItem('defaultDriverSchedule');
    return defaultSchedule ? JSON.parse(defaultSchedule) : [];
}
