<template>
    <div class="navigation-buttons">
        <Button variant="basic" size="small" @onClick="goToPrevious">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="24"
                fill="currentColor"
                viewBox="0 0 16 16"
            >
                <path
                    fill-rule="evenodd"
                    d="M10.707 3.293a1 1 0 010 1.414L6.414 8l4.293 4.293a1 1 0 11-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                />
            </svg>
        </Button>
        <Button variant="basic" size="small" @onClick="goToToday">Heute</Button>
        <Button variant="basic" size="small" @onClick="goToNext">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                viewBox="0 0 16 16"
            >
                <path
                    fill-rule="evenodd"
                    d="M5.293 3.293a1 1 0 010 1.414L9.586 8l-4.293 4.293a1 1 0 101.414 1.414l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 0z"
                    clip-rule="evenodd"
                />
            </svg>
        </Button>
    </div>
</template>

<script>
import Button from '@/components/widgets/Button.vue';

export default {
    name: 'NavigationButtons',
    components: {
        Button,
    },
    methods: {
        goToToday() {
            this.$emit('navigate', { action: 'today' });
        },
        goToPrevious() {
            this.$emit('navigate', { action: 'previous' });
        },
        goToNext() {
            this.$emit('navigate', { action: 'next' });
        },
    },
};
</script>

<style scoped>
.navigation-buttons {
    display: flex;
    padding: 4px;
    gap: 4px;
}
</style>
