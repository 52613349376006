var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ToggleSwitchWrap"
  }, [_vm._t("left"), _c('div', {
    staticClass: "ToggleSwitch",
    class: {
      'ToggleSwitch--hasError': _vm.hasError
    }
  }, [_c('input', {
    staticClass: "ToggleSwitch-input",
    attrs: {
      "type": "checkbox",
      "id": _vm.name || _vm.value,
      "name": _vm.name,
      "disabled": _vm.isDisabled
    },
    domProps: {
      "checked": _vm.value
    },
    on: {
      "change": _vm.handleChange
    }
  }), _c('label', {
    staticClass: "ToggleSwitch-label",
    attrs: {
      "for": _vm.name || _vm.value
    }
  })]), _vm._t("right")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }