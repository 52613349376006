var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Calendar",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', {
    staticClass: "Headline"
  }, [_c('h1', {
    staticClass: "Title"
  }, [_vm._v("Ihr Schichtplanner")])]), _c('div', {
    staticClass: "PlannerWrap"
  }, [!_vm.isFetchingData ? _c('div', {
    staticClass: "MapWrapper"
  }, [_c('l-map', {
    ref: "mapObject",
    staticStyle: {
      "height": "300px",
      "width": "100%"
    },
    attrs: {
      "zoom": _vm.zoom,
      "center": _vm.center
    }
  }, [_c('l-tile-layer', {
    attrs: {
      "url": _vm.tileUrl,
      "attribution": _vm.attribution
    }
  }), _vm._l(_vm.employeeLocations, function (location, index) {
    return _c('l-marker', {
      key: index,
      ref: "marker",
      refInFor: true,
      attrs: {
        "lat-lng": [location.lat, location.lng]
      },
      on: {
        "click": function click($event) {
          return _vm.onMarkerClick(location);
        }
      }
    }, [_c('l-popup', [_vm._v(_vm._s(location.name))])], 1);
  })], 2)], 1) : _vm._e(), _c('AssigneeList', {
    attrs: {
      "assignees": _vm.employeeLocations,
      "selectedAssignee": _vm.selectedAssignee
    },
    on: {
      "assignee-click": _vm.onAssigneeClick
    }
  }), _c('div', {
    staticStyle: {
      "margin": "10px 0"
    }
  }, [_c('Schedular', {
    attrs: {
      "events": _vm.driverSchedule,
      "resources": _vm.currentCars,
      "assignees": _vm.currentEmployees,
      "variant": _vm.variant
    },
    on: {
      "onSaveAsDefault": _vm.handleSaveAsDefault,
      "onLoadDefault": _vm.loadDefaultSchedule,
      "onToggle": _vm.handleToggle,
      "onEventSave": _vm.handleEventSave,
      "onEventDelete": _vm.handleDeleteEvent
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }