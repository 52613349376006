<template>
    <div class="current-view-indicator">
        {{ formattedCurrentView }}
    </div>
</template>

<script>
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { de } from 'date-fns/locale';
export default {
    name: 'CurrentViewIndicator',
    props: {
        currentDate: {
            type: Date,
            default: () => new Date(),
        },
    },
    computed: {
        formattedCurrentView() {
            const weekStart = startOfWeek(this.currentDate, { weekStartsOn: 1 });
            const weekEnd = endOfWeek(this.currentDate, { weekStartsOn: 1 });

            return `${format(weekStart, 'PPP', { locale: de })} - ${format(weekEnd, 'PPP', {
                locale: de,
            })}`;
        },
    },
};
</script>

<style scoped lang="scss">
.current-view-indicator {
    /* Styles for your indicator */
    text-align: center;
    font-weight: bold;
    margin: 10px 0;
}
</style>
