<template>
    <Modal
        :show="isModalOpen"
        :title="modalTitle"
        :action="{ text: 'Speichern', color: 'green', callback: handleSaveEvent }"
        :cancel="{ text: 'Abbrechen', callback: handleCancelEvent }"
        :isLoading="isSavingEvent"
        @onModalClose="handleCancelEvent"
        class="EventModal"
    >
        <p>{{ modalDescription }}</p>
        <br />
        <div class="InputWrap">
            <Dropdown
                variant="single-column"
                :hasRemoveIcon="false"
                :items="drivers.filter(d => d.isVisible)"
                :selected="internalEvent.title"
                hasSearch
                placeholder="Fahrer"
                @onItemSelect="handleDriverSelect"
            />
        </div>
        <div class="InputWrap">
            <label for="notice">Notiz</label>
            <textarea class="Input" id="notice" v-model="internalEvent.notice" required></textarea>
            <span class="Hint">Fügen Sie hier eine Beschreibung hinzu.</span>
        </div>
        <!-- <div class="InputWrap">
            <label for="startDate">Startdatum</label>
            <input
                class="Input"
                id="startDate"
                type="datetime-local"
                v-model="internalEventStartDate"
                required
            />
            <span class="Hint">Fügen Sie hier ein Startdatum hinzu.</span>
        </div>
        <div class="InputWrap">
            <label for="endDate">Enddatum</label>
            <input
                class="Input"
                id="endDate"
                type="datetime-local"
                v-model="internalEventEndDate"
                required
            />
            <span class="Hint">Fügen Sie hier ein Enddatum hinzu.</span>
        </div> -->
    </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/widgets/Modal.vue';
import Dropdown from '@/components/widgets/Dropdown.vue';
import { formatISO } from 'date-fns';
export default {
    name: 'EventModal',
    components: {
        Dropdown,
        Modal,
    },
    props: {
        isModalOpen: Boolean,
        event: {
            type: Object,
            default: () => ({ title: '', description: '', startDate: '', endDate: '' }),
        },
        isSavingEvent: Boolean,
    },
    data() {
        return {
            drivers: JSON.parse(localStorage.getItem('drivers')).filter(d => d.isVisible),
            internalEvent: {
                title: '',
                description: '',
                startDate: new Date().toISOString(),
                endDate: new Date().toISOString(),
            },
        };
    },
    watch: {
        event: {
            immediate: true,
            handler() {
                console.log('event', this.event);
                this.internalEvent = { ...this.event };
            },
        },
    },
    computed: {
        ...mapGetters(['cars']),
        internalEventStartDate: {
            get() {
                return formatISO(this.event.startDate, { representation: 'date' });
            },
            set(value) {
                this.internalEvent.startDate = new Date(value);
            },
        },
        internalEventEndDate: {
            get() {
                return formatISO(this.event.endDate, { representation: 'date' });
            },
            set(value) {
                this.internalEvent.endDate = new Date(value);
            },
        },
        modalTitle() {
            const currentCar = this.cars.find(car => car.uuid === this.internalEvent.resourceId);

            const licenseNumber = currentCar
                ? currentCar?.currentInstallation?.licenseNumber || currentCar.value
                : '';
            return `Fahrerzuweisung für ${licenseNumber}`;
        },
        modalDescription() {
            return this.internalEvent.id
                ? 'Bearbeiten Sie die Fahrerzuweisung'
                : 'Erstellen Sie eine neue Fahrerzuweisung';
        },
    },
    methods: {
        handleDriverSelect({ item }) {
            this.internalEvent.title = item.value;
        },
        handleSaveEvent() {
            if (this.internalEvent.title === '') {
                this.$toasted.show('Bitte wählen Sie einen Fahrer aus.');
                return;
            }
            this.$emit('onSave', this.internalEvent);
            this.internalEvent = {
                title: '',
                description: '',
                startDate: new Date().toISOString(),
                endDate: new Date().toISOString(),
                driver: '',
            };
        },
        handleCancelEvent() {
            this.$emit('onCancel');
            this.internalEvent = {
                title: '',
                description: '',
                startDate: new Date().toISOString(),
                endDate: new Date().toISOString(),
                driver: '',
            };
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
.EventModal {
    .InputWrap {
        margin-bottom: 1rem;
    }
    .Selected {
        width: 100%;
    }
}
</style>
